import {
	type ErrorResponse,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { getErrorMessage } from '#app/utils/misc.tsx'
import { TextElem } from './ui/radixUiTheme/Typography'

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<p>
			{error.status} {error.data}
		</p>
	),
	statusHandlers,
	unexpectedErrorHandler = error => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
	const error = useRouteError()
	captureRemixErrorBoundaryError(error)
	const params = useParams()

	if (typeof document !== 'undefined') {
		console.error(error)
	}

	return (
		<div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-2">
			<TextElem className="mb-4 text-2xl font-bold">
				OOPS, I DID IT AGAIN!
			</TextElem>
			<pre className="max-w-full rounded bg-white p-1 text-xs shadow-md">
				{`.-=--=***%%@@@@@@@@@@@@@.@@..:.-@@..@@@@%@@@@@@@@@%%%###%@%@@@@@@@@@%@%@@%@@@@@@@@@@@@@@@@@@@@@@@@@@
.*#:#*=++===-------=-==@.-@..@..@@..@++*+*+*#%##%%%@@@@@@@@@@@@@@@%%@@@@%%%%%%%#%%%%@@@@@@@@@@@@@@@@
=@@*@@@@@@@@@@@@@@@@@@%@..@..%..@..@@***###**#%%%####-*+*+*#####*##%###%##%%%##%%##***#**######%%%#*
.::............:=:..:-*@..@..@..@..@@%##%%@@%###@@@@@@@@@@@@@@@@@%@@@%@@@@%@@@#@%%@@@%@@@%%%%%%#@@@#
.+=--@@-%=-@@@@.-:@@@*.#@.........@:--@@.+.:=@%*:.#*+..:-+-.++*@@@*=@@@#.+%%%@#@@@@@%@@%%@@%@@@@%@@%
......................:@......+.:.@..@..+==#..:#++%.@@+=@.*@*#@..@%*@..@+@@@@@@@%%%@%%%%@@@@#.#@=%@.
@@@@@@@@@@@@@@@@@@@@@@@@......-.:%#=+=-=:.:-*-......-=..=....-#..-@@@..@@@.@@.=@%@@@@@@@@@@@*@.%#+*=
.+.*+++*+******#%%%##%@@.........@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@..@@.-.@.-@...@@@%@@@@@@@@@@@@@@@@@@
.*+*#++*++=+++**+++++%%@@......=@@***+****+*+++*+##*#-#**######@@..@.+..@...@@@@%%@%%%@%%@@@@@@%@@@%
=@@@@@@@@@@@@@@%#*##@#.@.@*-..+@@@@@@@@@@@@@@@@@@%@@@#@@@@@@@%%%@@.........@@@@@@@@@@@@@@@@@@@@@@@@@
......#%-...*@@==%@*@@.#...@@=:@@-...:@@*%+.=...#@@+#+:+@@@=--@@@@.........@@@*=%@@@#+#@@@@+=@@@@**:
.+::#@...=#@...%@%%=:..:#@@@@.@...-=+.:..............+*@-..@-=#..@@......-@:..@=@...@*@...@%%@..@%#.
...............@@=.....@%@#%#...:*#=.-.=-@@@@@..@.@:...+@..=..@........#@@%@-@@*%@..@=#@-.@-=@@.@*+.
@@@@@@@@@@@@@@@%.....+@@%#*#%@@@@@@.:.@@......@@#@.@.@*.@@@@@@@...@@@@@@.-..:.=@@@@@@@@@@@@@@@@@@@@@
.=**+*+***@*@@..-.:@@@%#####+%#*@#.@.@@............%.:.@.**++#@@@@@@@@@@@#-......*@@@%##%#%%%@%@@@@%
:%@@@*##*-@%....@@@@@@@@@@@@%@@@@.#.@%.............-.@-+@.@%*####**##%##@@@@%=.....+@@@%%%%%@%%%#%%%
..:.+@-.@@=..=-:+@@@*-...=#....:..@.@@................@@@.#@#*###%%%%%@%@%@@#@@.::..#+@@@%%%%#%@@@%#
.#=.@.*@@...+..#@@...*=:@..=.-.@.-%@*@.*.@@.........@..#@.-@@@@@%%@@@@%%%@@@@@@@#-...*#-@@%%@@@@@@@@
....@@@*..@@=*@@+.*.-............=+@.-..@@@+....@.@@@.:@==.:..+@@@+-=@@@*-=@@@@%%+:*...%%+@*+*@@@@-.
@@@%%%....=.:*@#@@@@@@@@@@@@@@@.#:-@=...............%..:.@..%-+...#+*...@##...@@%@%*....=#+@*=#..@*=
=*#:*:.@@@.%@@@@%*+==*****%++@@...%@##.........-......-@.+@.+.:@@%-.=#@#-.-*%%+.-**#.=@=-+#+*%@@@@#=
=*#@%..+.@...+@@#*@*+***++%:%#...:*:@%@....+....*%.....@..@.@@@@@@@@@@@@@@@@@@@@@@@@@#%...+@+%@@@@@@
-+##@@@::...@.%+@*@@@@#**%%##@..-.#+@.@.............:.@@@:-%.*++*+++*#####%@@*#==+*@=.@*.*#..*@%@@@%
:*##%#@+@...=.=.#..%##@@*%##%.=*.#%*@#@@...@@.=.-@....@@@:.%.*#*%%@%%@@@%@%@-@@@@@#@.+...-*.@@@@%@@%
+@@@@@@@@@@@#:--.+-..-*#@@%%@.#:*@+#@+@@.....-...-...@@-@@@@@@%@@%%%##*@%##+:.........*@@.@@@@@@#+=.
..=@@#:-..%@@@#=%=+*+..-@.-:#@%.@###*@@@@@...#@@...#@#%@@@@**+@**++=+@%.-....=.....@@@+@@@@%#%%@@*..
.@@....:+@...@%*#**=**+...#:*@.@#%#*@@@:@@@.......@@###=+=++#+.*#+*-=+...=.....*@@@%+#%@@@@%%@@%%:*=
...++=-..:#+...=-=*+*+*%.=@.%.*@#%#=@@%:@@@@=@@@@@@%@#=@##%#*+*...:-...-=....@@@*=#=*++%@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@*=+=--++..=-@##**-@-**@....=@..%@=.=%%#****++::-===::....-%*-#@@@@@@@@@@@%%%%@%@@%
%@@@@%%%%##%@@%%%@@@*#*#.#.=.@@*@+*+*#%*%#%..=..-.@:%@##==++*+#=:==+-.:...+*%@@@%@%%%#%%%%@@@@@@@@@%
+%########****##*##%@@-@@::.@#.%=#*@+#%+%%%+@@@@=.=%%**=+***+***=----..:=*@@@@%%+%%%%%%%%%###@%%@@@%
:@@@@@@@@@@@@@@@@@@@@@@@%=.#%-@*-%*++:@#-#=#@#@@@@@#%*-****+***#*===-+#@@@@@@@@@%@@@@@@@@@@@@@@@@@@@
.=+@%*#=.=---:-.==+-:.-*+:+@.%*=@#-+*#*%=+#:--**=+=*==***********+=+=@@..#+*@..@:+%..@++#+.@**++++##
.%@..**+@@..#**@@..@**@*..@=+@=*%-+**%=@%+*@-::#*=@++#######**###*=:##..=##@...@##@..@+#@..@#%###@%+
:###*+-.::+=-:-::.:----%.==-@==+-*###@..@@@@.*@..@.%@@@=+=+*####*#+=%@@@@+-+@@@+:-@@@@+=%@@@=*#+**=-
-%%@@@@@@@@@@@@@@@@@@@@*.......*................:..............:::..@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@`}
			</pre>
			<div className="container flex items-center justify-center bg-white p-10 text-h2">
				{isRouteErrorResponse(error)
					? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
							error,
							params,
						})
					: unexpectedErrorHandler(error)}
			</div>
		</div>
	)
}
